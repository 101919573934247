import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import axios from 'axios'


const Slot = ({start, end, duration}) => {
    const getDuration = () => {
       if(duration < 3600){
          return _.round(duration / 60, 2) + 'min'
       }
       return 0
    }
    return (
       <>
          {start.format('HH[h]mm') + ' - ' + end.format('HH[h]mm')} <span>({getDuration()})</span>
       </>
    )
 }
 
 
 const Calendar = ({expert, product}) => {
 
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedSlot, setSelectedSlot] = useState(null)
 
    const [currentMonth, setCurrentMonth] = useState(null)
    const [currentYear, setCurrentYear] = useState(null)
    const [availabilities, setAvailabilities] = useState([])
    const [slotExpand, setSlotExpand] = useState(false)
 
    const getCurrentDate = () => {
       return moment(currentYear+'-'+_.padStart(currentMonth, 2, '0')+'-01 00:00:00')
    }
 
    const getCurrentDateStart = () => {
       const currentDate = getCurrentDate()
       return currentDate.startOf('month').format('YYYY-MM-DD')
    }
 
    const getCurrentDateEnd = () => {
       const currentDate = getCurrentDate()
       return currentDate.add(1, 'months').startOf('month').format('YYYY-MM-DD')
    }
 
    const getDates = () => {
       const getAvailabilities = () => {
          return availabilities.map((item) => {
             return moment(item.start).format('Y-MM-DD')
          })
       }
       return _.uniq(getAvailabilities())
    }
 
    const nextMonth = () => {
       const currentDate = getCurrentDate()
       currentDate.add(1, 'months')
       setCurrentMonth(currentDate.format('M'))
       setCurrentYear(currentDate.format('YYYY'))
    }
 
    const prevMonth = () => {
       const currentDate = getCurrentDate()
       currentDate.subtract(1, 'months')
       setCurrentMonth(currentDate.format('M'))
       setCurrentYear(currentDate.format('YYYY'))
    }
 
    useEffect(() => {
       setCurrentMonth(moment().format('M'))
       setCurrentYear(moment().format('YYYY'))
    }, [])
 
    useEffect(() => {
       if(currentMonth && currentYear) {
          const startDate = getCurrentDateStart()
          const endDate = getCurrentDateEnd()
          axios('/expert/' + _.get(expert, 'slug') + '/availabilities?start=' + getCurrentDateStart() + '&end=' + getCurrentDateEnd() + '&product=' + product.id).then((res) => {
             const {data} = res
             setAvailabilities(data)
          }).catch((e) => {
          })
       }
    }, [currentMonth, currentYear])
 
    const getHeader = () => {
       return (
          <div className={'head'}>
             <div className="title">Booker {_.get(expert, 'firstname')}</div>
             <p>Choississez une date qui vous correspond pour profiter d’une entrevue</p>
          </div>
       )
    }
 
    const getDays = (days) => {
       const weeks = _.chunk(days, 7)
       const getWeek = (week) => {
          return week.map(({available, date, day, index}) => {
             const dayClass = ['day']
             if(available){
                dayClass.push('available')
             }
             return (
                <div key={index} className={dayClass.join(' ')} onClick={() => setSelectedDate(date)}>
                   <span>{day == 0 ? '' : day}</span>
                </div>
             )
          })
       }
       return weeks.map((week, weekIndex) => {
          return (
             <div key={weekIndex} className="week">
                {getWeek(week)}
             </div>
          )
       })
    }
 
    const getWeeks = () => {
 
 
       const dates = getDates()
       const weekSeq = ['L','M','M','J','V','S','D']
 
       const currentDate = getCurrentDate()
       const daysInMonth = currentDate.daysInMonth()
       const dayOfMonthStart = currentDate.format('D')
 
       let days = []
       let index = 0
 
       if(dayOfMonthStart > 0){
          let i = 0
          while(i < dayOfMonthStart) {
             days.push({
                name: weekSeq[i],
                day: '',
                date: '',
                available: false,
                index
             })
             i++
             index++
          }
       }
 
       let i = 0
       while(i < daysInMonth) {
          days.push({
             name: weekSeq[currentDate.format('d')],
             day: currentDate.format('D'),
             date: currentDate.format('YYYY-MM-DD'),
             available: _.includes(dates, currentDate.format('YYYY-MM-DD')) ? true : false,
             index
          })
          currentDate.add(1, 'days')
          i++
          index++
       }
 
       
       while(days.length % 7 != 0) {
          days.push({
             name: '',
             day: '',
             date: '',
             available: false,
             index
          })
          index++
       }
       
       return (
          <div className="booking__weeks">
             {getDays(days)}
          </div>
       )
 
       return null
    }
 
    const getPrevMonth = () => {
       return (
          <div className="prev" onClick={() => prevMonth()}>
             <i className="fa-solid fa-chevron-left"></i>
          </div>
       )
    }
 
    const getNextMonth = () => {
       return (
          <div className="next" onClick={() => nextMonth()}>
             <i className="fa-solid fa-chevron-right"></i>
          </div>
       )
    }
 
    const getNameMonth = () => {
       const monthsNames = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']
       return (
          <div className="booking__month_name">
             {monthsNames[currentMonth - 1]}
          </div>
       )
    }
 
    const getSlots = () => {
       const slots = _.filter(availabilities, function(o) {
          const availabilityDate = moment(o.start).format('Y-MM-DD')
          return availabilityDate == selectedDate ? true : false
       })
       const slotsSliced = slotExpand ? slots : _.slice(slots, 0, 7)
       return slotsSliced.map((slot) => {
          const {start, end, duration} = slot
          const dateStart = moment(start)
          const dateEnd = moment(start).add(duration, 'seconds')
          return (
             <div className="slot" onClick={() => setSelectedSlot(slot)}>
                <Slot start={dateStart} end={dateEnd} duration={duration} />
             </div>
          )
       })
    }
 
    const getExpand = () => {
      const slots = _.filter(availabilities, function(o) {
         const availabilityDate = moment(o.start).format('Y-MM-DD')
         return availabilityDate == selectedDate ? true : false
      })
      console.log(slots.length)
      if (slots.length <= 7) {
         return null
      }
      if(slotExpand) {
         return (
            <button className={'btn btn-empty btn-stretch'} onClick={() => setSlotExpand(false)}>Voir moins</button>
         )
      }
      return (
         <button className={'btn btn-empty btn-stretch'} onClick={() => setSlotExpand(true)}>Voir pluses</button>
      )
    }
 
    const _handleSubmit = () => {
       const dateStart = moment(selectedSlot.start)
       const dateEnd = moment(selectedSlot.start).add(selectedSlot.duration, 'seconds')
       const redirect = '/expert/' + _.get(expert, 'slug') + '/checkout?start=' + dateStart.unix() + '&end=' + dateEnd.unix() + '&product=' + product.id
       window.location.href = redirect
    }
 
    const getSubmit = () => {
       if(selectedDate && selectedSlot){
          const dateStart = moment(selectedSlot.start)
          return (
             <button className="btn btn-default btn-stretch" onClick={() => _handleSubmit()}>Choisir le créneau de {dateStart.format('HH[h]mm')}</button>
          )
       }
       return null
    }
 
    const getBody = () => {
       if(selectedDate){
          return (
             <div className="booking__body">
                <div className="booking__slots">
                   {getSlots()}
                   {getExpand()}
                   {getSubmit()}
                </div>
             </div>
          )
       }
       return (
          <div className="booking__body">
             <div className="booking__calendar">
                <div className="booking__month">
                   {getPrevMonth()}
                   {getNameMonth()}
                   {getNextMonth()}
                </div>
                {getWeeks()}
             </div>
          </div>
       )
    }
 
    return (
       <div className={'expert__booking'}>
          {getHeader()}
          {getBody()}
       </div>
    )
 
 }

export default Calendar